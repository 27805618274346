<template>
  <div class="card-header">
    <h4>Price {{ formName }}</h4>
    <div class="card-header-form">
      <div class="input-group">
        <button
          class="btn btn-primary"
          @click="toggleModalPrice"
          v-if="!showModalPrice"
        >
          Create
        </button>
        <button class="btn btn-primary" @click="toggleModalPrice" v-else>
          Product Price
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["formName"],
};
</script>
